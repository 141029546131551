import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from '../../../components/Layout/Layout'
import Therapist from "../../../components/Therapist/Therapist"

// markup

const name = 'Jeanne Bunkers'
const license = 'MSW, LCSW-PIP'
const areas = [
    'Anxiety',
    'Depression',
    'Grief and Loss',
    'ADD/ADHD',
    'Trauma',
    'Life Transitions and Adjustments',
]

const IndexPage = () =>
(
    <Layout className="">
        <Therapist
            name={name}
            license={license}
            areas={areas}
            photo={(<StaticImage src='./jeanne-bunkers.jpg' alt={name} layout="constrained" className="shadow" />)}
        >

        <p>Jeanne is a Certified Social Worker in Private Independent Practice. She began her career in mental health working with adolescents in residential treatment centers, parents in foster care licensing, and providing counseling at community agencies. She has been in private practice since 2002. Jeanne works with children, adolescents, and adults.</p>
        </Therapist>
    </Layout>
)

export default IndexPage
